(function(window) {
    var key = 'search_config';

    function safe_start() {
        window.mL = window.mL || {};

        window.mL.search = {
            app_id: 'LOM2GLHI8K',
            api_key: '7203137d89bb8704545c2c6d17a88ef7',
            item_index: 'mlahart',
            shop_index: 'mlahart_university',
            item_index_carousel: 'items_carousel'
        };


        ///////////////////////////////////////////////////////////////////////////////////
        ///////// home page search

        // https://github.com/algolia/autocomplete.js/blob/master/README.md
        
        var home = {
            global_options: {
                hint: true,
                debug: false,
                openOnFocus: true,
                templates: {
                    dropdownMenu: function() {
                        return '<div class="mlahart_dataset_wrap">' +
                            '<div class="mlh_ac-dataset-university"></div>' +
                            '<div class="mlh_ac-dataset-items"></div>' +
                            '</div>';
                    },
                    empty: function(obj) {
                        return '<div class="mlahart_dark_row">No results for "' + obj.query + '"</div>';
                    }
                    /*,
                    header: '<div class="mlahart_dark_row">header</div>',
                    footer: '<div class="mlahart_dark_row">footer</div>'
                    */
                },
                cssClasses: {
                    root: 'mlahart_autocomplete',
                    prefix: 'mlh_ac',
                    dropdownMenu: 'dropdown-menu',
                    input: 'input',
                    hint: 'hint',
                    suggestions: 'suggestions',
                    suggestion: 'suggestion',
                    cursor: 'cursor',
                    dataset: 'dataset',
                    empty: 'empty'
                }
            },
            shop_index: {
                name: 'university',
                displayKey: 'shop_name',
                debounce: 100,
                templates: {
                    suggestion: function(obj) {
                        var link = '<a title="' + obj.fullname + '" class="shop_link cf" href="/' + obj.urlComponent + '/">';
                        link += '<span class="shop_icon" style="background-image: url(https://img.mlahart.com/' + obj.threeDigitCode + '-menu.30.png)"></span>';
                        link += '<span class="shop_name">' + obj._highlightResult.name.value + '</span>';
                        link += '</a>';
                        return link;
                    }
                    /*,
                                header: function(obj) {
                                    //   return '<div class="mlahart_dark_row">Universities View All ></div>';
                                },
                                footer: function(obj) {
                                    // return '<div class="mlahart_dark_row">Shop Footer Content Goes Here</div>';
                                }*/
                }
            },
            shop_index_source_config: { hitsPerPage: 10, filters: 'live:true AND (type:University OR type:Business OR type:Fraternity OR type:Sorority)' },
            item_index: {
                name: 'items',
                debounce: 100,
                displayKey: function(suggestion) {
                    return suggestion.copy_title || suggestion.sku;
                },
                templates: {
                    suggestion: function(obj) {
                        var link = '<a title="' + obj.copy_title + '" class="item_link cf" href="/' + obj.url_relative + '">';
                        link += '<table>';
                        link += '<tr>';
                        link += '<td style="width:60px;">';
                        link += '<div class="item_icon" style="background-image: url(' + obj.image_thumb + ')"></div>';
                        link += '</td>';
                        link += '<td>';
                        link += '<div class="item_name">' + obj._highlightResult.copy_title.value + '</div>';
                        link += '<div class="item_details">Item # ' + obj._highlightResult.sku.value + ' </div>';
                        link += '</td>';
                        link += '</tr>';
                        link += '</table>';
                        link += '</a>';
                        return link;
                    },
                    header: function(obj) {
                        return '<div class="mlahart_dark_row">Item results for "' + obj.query + '"</div>';
                    },
                    footer: function(obj) {
                        return '<div class="view_all"><a title="Click to view all results" href="/search?query=' + obj.query + '">Click to view all results <i class="fa fa-angle-right"></i></a></div>';
                    }
                }
            },
            item_index_source_config: { hitsPerPage: 5 }
        };


        window.mL.search.home = home;

    }

    try {
        safe_start();
    } catch (err) {
        if (window.mL_errors) {
            window.mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
            window.mL_errors(err);
        } else {
            console.error(err);
        }
    }


}(window));