(function(window, mL_config, mL) {
    "use strict";

    var key = 'error_handler';

    window.mL_errors_que = window.mL_errors_que || []; // create error que
    window.mL_errors = window.mL_errors || function(err) {
        window.mL_errors_que.push(err);
    };

    function safe_start() {

        var error_count = 0;
        var error_max = 5;

        var mL_errors_que = window.mL_errors_que || [];

        if (!mL_config) throw new Error('The mL_config global is not available');
        if (!mL) throw new Error('The mL global is not available');

        var init_tries = 0;
        var init_interval = false;

        var error_handler = false;
        var error_handler_backup = false;
        var acive_version = false;
        var ver = false;
        var uid = false;
        var jquery_error = false;
        var errors_reported = [];
        var force_img_send = false; //'test_err_code'; // 
        var force_backup_send = mL_config.force_backup_send; //true; // 
        var mlahart_uid = (mL_config.session && mL_config.session.mlahart_uid);
        var mlahart_uid_param = (mlahart_uid) ? ('&mlahart_uid=' + encodeURIComponent(mlahart_uid)) : '';


        function add_image(src, msg) {
            var _img = document.createElement('img');
            _img.src = src;
            _img.alt = msg;
            _img.height = '1';
            _img.width = '1';
            document.body.appendChild(_img);
        }


        function send_error_log_backup(obj) {
            if(error_count > error_max) return;

            var params = [];

            for (var n in obj) {
                if (obj.hasOwnProperty(n) && n !== 'message') {
                    params.push(n + '=' + encodeURIComponent(obj[n]));
                }
            }

            var image_src = '/notify/error/error.png?message=' + encodeURIComponent(obj.message) + mlahart_uid_param;

            if (params.length > 0) {
                image_src = image_src + '&' + params.join('&');
            }

            add_image(image_src, obj.message);
            error_count += 1;
        }

        function send_error_log(data, callback) {
            if(error_count > error_max) return;
            if (!data || !data.message || typeof callback !== 'function') throw new Error('bad request - missing data or data.message');

            var _url = '/notify/error?message=' + encodeURIComponent(data.message) + mlahart_uid_param;
            var data_str = JSON.stringify(data);
            var req = new XMLHttpRequest();

            req.open("POST", _url, true);
            req.setRequestHeader("Content-type", "application/json");
            req.onreadystatechange = function() {
                if (req.readyState === 4) {
                    if (req.status === 200) {
                        callback(force_img_send);
                    } else {
                        callback(req.status);
                    }
                }
            };
            req.send(data_str);
            error_count += 1;
        }

        function get_backup_error_obj(err_obj) {
            var o = {
                message: err_obj.message || 'no message on this error',
                timestamp: new Date().getTime().toString(),
                ml_href: mL_config.href,
                ml_hostname: mL_config.hostname
            };
            if (acive_version) o.version = acive_version;
            if (uid) o.uid = uid;
            return o;
        }

        function BackupHandler() {
            function report(err) {
                var data = get_backup_error_obj(err);

                send_error_log(data, function(err_code) {
                    if (err_code) {
                        data.error_code = err_code;
                        send_error_log_backup(data);
                    }
                });
            }

            function setUser(new_uid) {
                if (new_uid && new_uid !== uid) {
                    uid = new_uid;
                }
            }

            return {
                report: report,
                setUser: setUser

            };
        }

        function set_uid() {
            if (uid || !error_handler || !mL.user || !mL.user.mlahart_uid || typeof mL.user.mlahart_uid !== 'function') return;

            try {
                if (mlahart_uid) {
                    uid = mlahart_uid;
                    error_handler.setUser(uid);
                } else {
                    uid = mL.user.mlahart_uid();
                    if (!uid) return;
                    error_handler.setUser(uid);
                }
            } catch (err) {
                if (mL_config.is_loc) console.error(err);
            }
        }

        function set_ver() {
            try {
                ver = mL.version();
                acive_version = ver.text;
            } catch (err) {
                acive_version = 'unknown_version';
            }
        }

        function report_error(error) {
            if (typeof error === 'string') error = new Error(error);

            if (errors_reported.indexOf(error) !== -1) return;

            errors_reported.push(error);

            if (!uid) set_uid();
            if (!ver) set_ver();

            try {

                error_handler.report(error);

                if (force_backup_send && error_handler_backup && error_handler_backup.report) {
                    error_handler_backup.report(error);
                }

                if (mL_config.is_loc) console.error(error);

            } catch (err) {
                console.error(err);
            }
        }

        function empty_que() {
            if (mL_errors_que.length < 1) return;

            var i = 0,
                l = mL_errors_que.length;

            for (i; i < l; i += 1) {
                report_error(mL_errors_que[i]);
            }
            mL_errors_que = [];
        }

        function init_handler() {

            if (error_handler) return;

            var _loaded = (window.StackdriverErrorReporter && typeof window.StackdriverErrorReporter === 'function');

            if (!_loaded) {
                if (init_tries < 3) return;

                mL_errors(new Error('Stackdriver failed to load after 3 attempts'));

                error_handler = new BackupHandler();

            } else {

                try {
                    //throw 'test error';

                    error_handler = new StackdriverErrorReporter();

                    error_handler.start({
                        key: 'AIzaSyBhvlQPXXAXVpiYYd4py0nDxsN6RUWnUZA',
                        projectId: 'mlahart-1151',
                        service: mL_config.hostname || 'unknown_hostname',
                        version: acive_version
                    });

                    error_handler_backup = new BackupHandler();

                } catch (err) {
                    mL_errors(new Error('Stackdriver failed to initialize'));

                    mL_errors(err);

                    error_handler = new BackupHandler();
                }
            }


            try {
                empty_que();
            } catch (err) {
                mL_errors(err);
            }

        }

        function process_error(err) {
            //console.error(err);
            return (error_handler) ? report_error(err) : mL_errors_que.push(err);
        }


        window.mL.report_error = process_error;
        window.mL_errors = process_error;

        try {
            window.onerror = function(message, source, lineno, colno, error) {
                try {
                    if (error) {
                        return process_error(error);
                    } else {
                        var msg_arr = [];
                        if (message) msg_arr.push(message);
                        if (source) msg_arr.push(source);
                        if (lineno) msg_arr.push(lineno);
                        if (colno) msg_arr.push(colno);
                        return process_error(msg_arr.join(' | '));
                    }
                } catch (err) {
                    return process_error(err);
                }
            };

        } catch (err) {
            process_error(err);
        }

        init_handler();

        init_interval = window.setInterval(function() {
            if (!error_handler && init_tries < 6) {
                init_tries += 1;
                init_handler();
                if (error_handler) {
                    clearInterval(init_interval);
                }
            } else {
                clearInterval(init_interval);
            }
        }, 500);

    }

    try {
        safe_start();
    } catch (err) {
        mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
        mL_errors(err);
    }


}(window, window.mL_config, window.mL, window.mL_errors));