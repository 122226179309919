(function(window, document, mL_config, mL, mL_errors, mL_events) {
    "use strict";

    var key = 'last_script';

    function safe_start() {
        if (!mL_config) throw new Error('The mL_config global is not available');
        if (!mL_errors) throw new Error('The mL_errors global is not available');
        if (!mL) throw new Error('The mL global is not available');

        var pageviews_events = 0;

        var _params = mL.params || mL_config.params || {};

        function load_after_timeout(action, delay, n) {
            window.setTimeout(function() {
                //console.log(action, n + ' - ' + delay);
                action.init();
            }, delay);
        }

        function _load_analytics() {
            if (mL.no || mL_config.do_not_track || !mL_config.tags || mL_config.is_scraper) return;
            for (var n in mL_config.tags) {
                if (mL_config.tags.hasOwnProperty(n) && mL[n] && mL[n].init && !mL[n].is_init) {
                    load_after_timeout(mL[n], mL_config.tags[n], n);
                }
            }
        }

        function _pre_load_analytics() {
            if (mL.no || mL_config.do_not_track || !mL_config.tags) return;
            for (var n in mL_config.tags) {
                if (mL_config.tags.hasOwnProperty(n) && mL[n] && mL[n].init && mL_config.preload[n]) {
                    mL[n].init();
                }
            }
        }

        function ml_init() {

            if (_params.select) {
                try {
                    window.addEventListener("load", function() {
                        if (mL.menu) {
                            mL.menu.select(_params.select);
                            mL.menu.open();
                        }
                    });
                } catch (err) {
                    mL_errors(err);
                }
            }

            if (_params.offer) {
                try {
                    document.getElementById('mLmodal').setAttribute('class', 'ng-scope active');
                } catch (err) {
                    mL_errors(err);
                }
            } else if (_params.show && _params.show === 'holiday' && tjb && tjb.toggleMsg) {
                try {
                    tjb.toggleMsg();
                } catch (err) {
                    mL_errors(err);
                }
            }
        }

        function send_pageview_event() {
            var _event;
            var _data;

            if (mL_config.is_checkout) {
                _event = 'order.pageview';
                _data = {
                    url: mL_config.href
                };
            } else {
                _event = 'page';
                _data = {
                    page: {
                        event: _event,
                        title: document.title,
                        url: mL_config.href
                    }
                };
            }

            mL_events.publish(_event, _data);

            if (mL_config.DEBUG) console.log(key + ' - send_pageview_event fired', { _event: _event, _data: _data });
        }

        _pre_load_analytics();

        window.addEventListener("DOMContentLoaded", function() {
            try {
                ml_init();
            } catch (err) {
                mL_errors(err);
            }
            if (mL_config.DEBUG) console.log('DOMContentLoaded event fired');
        }, false);

        window.addEventListener("load", function() {
            try {
                _load_analytics();
            } catch (err) {
                mL_errors(err);
            }

            setTimeout(function() {
                if (mL_config.lazy && mL_config.lazy.active) {
                    mL_config.lazy.ready();
                }
                //mL_events.publish('timing', { action: 'lazy_load' });
            }, 500);

            if (pageviews_events < 1) {
                try {
                    send_pageview_event();
                } catch (err) {
                    mL_errors(err);
                }
            }

            if (mL_config.DEBUG) console.log('load event fired');
        }, false);


        function update_shop(msg, data) {
            var shop = data.shop;
            var curr_shop = mL.user.shop();
            var new_shop;
            if (shop && shop.name && shop.color && shop.lp_code && shop.urlc) {
                new_shop = { 'shop_name': shop.name, 'shop_urlc': shop.urlc, 'shop_lp_code': shop.lp_code, 'shop_color': shop.color, 'shop_type': shop.type };
                if (!curr_shop) {
                    if (mL_config.DEBUG) console.log(key + ' - ' + msg + ' - missing current shop');
                    return mL.user.shop(new_shop);
                } else if (JSON.stringify(curr_shop) !== JSON.stringify(new_shop)) {
                    if (mL_config.DEBUG) console.log(key + ' - ' + msg + ' - updated');
                    return mL.user.shop(new_shop);
                } else {
                    if (mL_config.DEBUG) console.log(key + ' - ' + msg + ' - no update required');
                    return false;
                }
            }
        }

        mL_events.subscribe('page', function(msg, data) {
            if (mL_config.AUDIT) console.log('pubsub ' + key + ' - ' + msg, data);

            pageviews_events += 1;

            if (mL.user && mL.user.shop && typeof mL.user.shop === 'function') {
                update_shop(msg, data);
            }

            var ping_url = '/ping.gif?';
            var ping_img = false;
            var param_arr = ['action=pageview'];

            param_arr.push(('&ts=' + new Date().getTime().toString()));

            if (data && data.page && data.page.url) {
                param_arr.push(('page=' + encodeURIComponent(data.page.url)));
            }

            if (mL_config.session) {
                if (mL_config.session.mlahart_uid) {
                    param_arr.push(('&mlahart_uid=' + encodeURIComponent(mL_config.session.mlahart_uid)));
                }
                if (mL_config.session.ga_uid) {
                    param_arr.push(('&ga_uid=' + encodeURIComponent(mL_config.session.ga_uid)));
                }
                if (mL_config.session.ga4_uid) {
                    param_arr.push(('&ga4_uid=' + encodeURIComponent(mL_config.session.ga4_uid)));
                }
            }


            ping_img = new Image(1, 1);
            ping_img.src = ping_url + param_arr.join('&');
        });
    }


    try {
        safe_start();
    } catch (err) {
        if (mL_errors) {
            mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
            mL_errors(err);
        } else {
            console.error(err);
        }
    }


}(window, document, window.mL_config, window.mL, window.mL_errors, window.mL_events));

(function(window, mL_config, mL_errors) {
    if (window.mL_errors_que) {
        //console.log('mL_errors_que', window.mL_errors_que);
    }

    function cleanup() {
        var keys = ['DEBUG', 'AUDIT', 'tjb.recentitems', 'ltk-recentlyViewed'];
        var i = 0,
            l = keys.length;
        for (i; i < l; i += 1) {
            if (localStorage.getItem(keys[i])) {
                localStorage.removeItem(keys[i]);
            }
        }
    }

    try {
        if (mL_config.ls) cleanup();
    } catch (err) {
        mL_errors(err);
    }
}(window, window.mL_config, window.mL_errors));


/*
(function(window, document, mL_config, mL, mL_errors, mL_events) {
    if (!mL_config.is_dev || !mL_config.ls) return;

    var active_mode = localStorage.getItem('mode');

    var mode = {
        reset: function() {
            localStorage.removeItem('mode');
            window.location.reload();
        },
        debug: function() {
            localStorage.setItem('mode', 'debug');
            window.location.reload();
        },
        debug_analytics: function() {
            localStorage.setItem('mode', 'debug_analytics');
            window.location.reload();
        },
        debug_gtag: function() {
            localStorage.setItem('mode', 'debug_gtag');
            window.location.reload();
        }
    };
    var dev_block = document.createElement('div');
    dev_block.setAttribute('style', 'position:fixed;bottom:0;left:0');

    function add_btn(txt, action){
        var _btn = document.createElement('div');
        _btn.setAttribute('style', 'font-size:12px;display:inline-block;padding: 5px 10px;margin:2px;cursor:pointer;border:1px solid #ddd;color:#555;');
        _btn.textContent = txt;
        _btn.addEventListener('click', action);
        dev_block.appendChild(_btn);        
    }

    if (active_mode) {
        add_btn('reset', mode.reset);
    }

    if (active_mode !== 'debug') {
        add_btn('debug', mode.debug);
    }

    if (active_mode !== 'debug_analytics') {
        add_btn('debug_analytics', mode.debug_analytics);
    }

    if (active_mode !== 'debug_gtag') {
        add_btn('debug_gtag', mode.debug_gtag);
    }

    document.body.appendChild(dev_block);

    // ?mode=reset
    // ?mode=debug
    // ?mode=debug_analytics
    // ?mode=debug_gtag

}(window, document, window.mL_config, window.mL, window.mL_errors, window.mL_events));
*/