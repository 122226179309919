(function(window, document, mL, mL_errors, mL_config) {
    "use strict";

    var key = 'mlahart_navigation';

    function safe_start() {

        function on_load() {

            // var nav_menu_icon = document.getElementById('nav_menu_icon');
            var view_wrap_element = document.getElementById('nav_container_content');
            var menu_label_elements = document.querySelectorAll('.menu_label');
            var menu_link_elements = document.querySelectorAll('.link_section');
            var class_element = document.body;

            // if (!nav_menu_icon) return mL_errors(new Error('missing required element #nav_menu_icon'));
            if (!class_element) return mL_errors(new Error('missing required element document.body'));
            if (!view_wrap_element) return mL_errors(new Error('missing required element nav_container_content'));
            if (!menu_label_elements) return mL_errors(new Error('missing required element .menu_label'));
            if (!menu_link_elements) return mL_errors(new Error('missing required element .link_section'));

            var class_list = {
                'open': 'menu_open',
                'opening': 'menu_opening',
                'closed': 'menu_closed',
                'closing': 'menu_closing',
            };

            var short_delay = 10;
            var long_delay = 100;
            var in_process = false;

            var desktop_query = (window.matchMedia) ? window.matchMedia('(min-width: 40.0625em)') : false;
            var is_desktop = (desktop_query) ? desktop_query.matches : true;

            var default_section = 'university';

            if (window.location.href.indexOf('gotovictorylane') !== -1) {
                default_section = 'nascar';
            }

            function reset_tabs() {
                var i = 0,
                    l = menu_label_elements.length;

                for (i; i < l; i += 1) {
                    menu_label_elements[i].className = 'menu_label';
                }
            }

            function reset_links() {
                var i = 0,
                    l = menu_link_elements.length;

                for (i; i < l; i += 1) {
                    menu_link_elements[i].className = 'link_section';
                }
            }

            function any_active_tabs() {
                var i = 0,
                    l = menu_label_elements.length,
                    is_active = false;

                for (i; i < l; i += 1) {
                    if (menu_label_elements[i].className.indexOf('active') !== -1) {
                        is_active = true;
                    }
                }
                return is_active;
            }

            function select_main_menu() {
                reset_tabs();
                reset_links();

                view_wrap_element.className = 'main_menu';
            }

            function select_menu_section(section) {
                reset_tabs();
                reset_links();
                document.getElementById(section + '_links').className = 'link_section active';
                document.getElementById(section + '_tab').className = 'menu_label active';

                view_wrap_element.className = 'category_menu';
            }

            function toggle_section(section) {
                if (is_desktop) return;

                if (document.getElementById(section + '_links').className.indexOf('active') !== -1) {
                    select_main_menu();
                } else {
                    select_menu_section(section);
                }
            }

            function init_view() {
                if (is_desktop) {
                    select_menu_section(default_section);
                } else {
                    select_main_menu();
                }
            }

            function is_open() {
                return class_element.className && class_element.className.indexOf(class_list.open) !== -1;
            }

            function has_class(class_string) {
                return class_element.className.indexOf(class_string) !== -1;
            }

            function remove_spaces(class_string) {
                return class_string.replace(/  /g, ' ').trim();
            }

            function open_menu() {
                if (in_process) return;

                in_process = true;

                var class_string = class_element.className || '';

                var clean_string = class_string.replace(class_list.closed, '').trim();

                class_element.className = remove_spaces(clean_string + ' ' + class_list.closed + ' ' + class_list.opening);

                setTimeout(function() {
                    class_element.className = remove_spaces(clean_string + ' ' + class_list.opening + ' ' + class_list.open);
                    setTimeout(function() {
                        class_element.className = remove_spaces(clean_string + ' ' + class_list.open);
                        in_process = false;
                    }, long_delay);
                }, short_delay);
            }

            function close_menu() {
                if (in_process || !is_open()) return;

                in_process = true;

                var class_string = class_element.className || '';

                var clean_string = class_string.replace(class_list.open, '').trim();

                class_element.className = remove_spaces(clean_string + ' ' + class_list.open + ' ' + class_list.closing);

                setTimeout(function() {
                    class_element.className = remove_spaces(clean_string + ' ' + class_list.closing + ' ' + class_list.closed);
                    setTimeout(function() {
                        class_element.className = remove_spaces(clean_string + ' ' + class_list.closed);
                        in_process = false;
                    }, short_delay);
                }, long_delay);
            }

            function toggle_menu() {
                return (is_open()) ? close_menu() : open_menu();
            }

            function goto(section) {
                select_menu_section(section);
                open_menu();
            }


            // make sure menu is closed on page load
            if (is_open()) close_menu();

            var menu = {
                toggle: toggle_menu,
                close: close_menu,
                open: open_menu,
                select: select_menu_section,
                main_menu: select_main_menu,
                toggle_section: toggle_section,
                init: init_view,
                goto: goto,
                select_dynamic: function() {
                    select_menu_section('dynamic');
                }
            };


            if (!mL_config.is_rgs) init_view();

            if (desktop_query && desktop_query.addEventListener) {
                desktop_query.addEventListener('change', function(changed) {
                    is_desktop = changed.matches;
                    if (is_desktop) {
                        if (!any_active_tabs()) {
                            select_menu_section(default_section);
                        }
                    } else {
                        select_main_menu();
                    }
                });
            }

            // expose methods 

            mL.menu = menu;
        }

        window.addEventListener("DOMContentLoaded", on_load, false);
    }

    try {
        if(mL_config.is_rgs) return;
        safe_start();
    } catch (err) {
        mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
        mL_errors(err);
    }


}(window, document, window.mL, window.mL_errors, window.mL_config));