(function(window, mL, mL_errors, mL_events, mL_config) {
    "use strict";

    var key = 'bing_analytics';

    window.uetq = window.uetq || [];

    function safe_start() {
        if (mL_config.do_not_track || !mL_config.tags || !mL_config.tags.uetq) return;

        mL.uetq = mL.uetq || {};


        mL_events.subscribe('page', function(msg, data) {
            if (mL_config.AUDIT) console.log('pubsub ' + key + ' - ' + msg, data);

            var _obj = {};

            _obj.page_title = data.page.title;
            _obj.page_path = data.page.url_rel;

            if (mL_config.DEBUG) console.log(key + ' - ' + msg, _obj);

            uetq.push('event', 'page_view', _obj);

        });

        mL_events.subscribe('order.confirmation', function(msg, data) {
            var _order = data.order;

            var _obj = {
                'event_category': 'checkout',
                'revenue_value': _order.revenue,
                'currency': _order.currency
            };

            uetq.push('event', 'purchase', _obj);


            if (mL_config.DEBUG) console.log(key + ' - ' + msg, { _obj: _obj });
        });


        mL.uetq.init = function() {
            if (mL.uetq.is_init || !mL.add_tracker || !uetq) return false;

            var f = function() {
                if (window.UET) {
                    var o = { ti: "4052752", disableAutoPageView: true };
                    o.q = window.uetq;
                    window.uetq = new UET(o);
                    window.uetq.push("pageLoad");
                }
            };

            mL.add_tracker('https://bat.bing.com/bat.js', 'bing', false, f);

            mL.uetq.is_init = true;

            if (mL_config.DEBUG) console.log(key + ' - plugin loaded');
        };


    }

    try {
        safe_start();
    } catch (err) {
        mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
        mL_errors(err);
    }


}(window, window.mL, window.mL_errors, window.mL_events, window.mL_config));