(function(window, mL_config, mL_errors) {
    'use strict';

    var key = 'browser_pub_sub';

    window.mL_events = window.mL_events || {};

    function safe_start() {        

        var messages = {},
            lastUid = -1,
            ALL_SUBSCRIBING_MSG = '*';

        function hasKeys(obj) {
            var key;

            for (key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return true;
                }
            }
            return false;
        }

        function callSubscriber(subscriber, message, data) {
            try {
                subscriber(message, data);
            } catch (err) {
                mL_errors(err);
            }
        }

        function deliverMessage(originalMessage, matchedMessage, data) {
            var subscribers = messages[matchedMessage];
            var s;

            if (!messages.hasOwnProperty(matchedMessage)) {
                return;
            }

            for (s in subscribers) {
                if (subscribers.hasOwnProperty(s)) {
                    callSubscriber(subscribers[s], originalMessage, data);
                }
            }
        }

        function createDeliveryFunction(message, data) {
            return function deliverNamespaced() {
                var topic = String(message),
                    position = topic.lastIndexOf('.');

                // deliver the message as it is now
                deliverMessage(message, message, data);

                // trim the hierarchy and deliver message to each level
                while (position !== -1) {
                    topic = topic.substr(0, position);
                    position = topic.lastIndexOf('.');
                    deliverMessage(message, topic, data);
                }

                deliverMessage(message, ALL_SUBSCRIBING_MSG, data);
            };
        }

        function hasDirectSubscribersFor(message) {
            var topic = String(message),
                found = Boolean(messages.hasOwnProperty(topic) && hasKeys(messages[topic]));
            return found;
        }

        function messageHasSubscribers(message) {
            var topic = String(message),
                found = hasDirectSubscribersFor(topic) || hasDirectSubscribersFor(ALL_SUBSCRIBING_MSG),
                position = topic.lastIndexOf('.');

            while (!found && position !== -1) {
                topic = topic.substr(0, position);
                position = topic.lastIndexOf('.');
                found = hasDirectSubscribersFor(topic);
            }

            return found;
        }
        /*
        function countAllSubscriptions(message) {
            var topic = String(message);
            var position = topic.lastIndexOf('.');
            var topics = {};
            var count = 0;

            topics[message] = true;

            while (position !== -1) {
                topic = topic.substr(0, position);
                position = topic.lastIndexOf('.');
                topics[topic] = true;
            }

            function count_keys(o) {
                var n;
                for (n in o) {
                    if (o.hasOwnProperty(n)) {
                        count += 1;
                    }
                }
            }

            var n;
            for (n in topics) {
                if (topics.hasOwnProperty(n)) {
                    if (messages[n]) {
                        count_keys(messages[n]);
                    }
                }
            }

            console.log('SUMMARY', { messages: messages, topics: topics, count: count });

            return count;
        }
        window.mL_events.countAllSubscriptions = countAllSubscriptions;
      
        */


        function publish(message, data) {
            message = (typeof message === 'symbol') ? message.toString() : message;

            var hasSubscribers = messageHasSubscribers(message);
            /*
            console.log('publish', { message: message, data: data, hasSubscribers: hasSubscribers });
            countAllSubscriptions(message);
            setTimeout(function() {
                countAllSubscriptions(message);
            }, 5000);
            */

            if (!hasSubscribers) {
                return false;
            }

            var deliver = createDeliveryFunction(message, data);

            deliver();

            return true;
        }

        window.mL_events.publish = function(message, data) {
            if (mL_config.DEBUG) console.log('EVENT - PUBLISHED - ' + message, data);
            return publish(message, data);
        };
        /*

        window.mL_events.publishSync = function(message, data) {
            return publish(message, data, true, window.mL_events.immediateExceptions);
        };
        */

        window.mL_events.subscribe = function(message, func) {
            if (typeof func !== 'function') {
                return false;
            }

            message = (typeof message === 'symbol') ? message.toString() : message;

            if (!messages.hasOwnProperty(message)) {
                messages[message] = {};
            }

            var token = 'uid_' + String(++lastUid);
            messages[message][token] = func;


            return token;
        };
        /*

        window.mL_events.subscribeAll = function(func) {
            return window.mL_events.subscribe(ALL_SUBSCRIBING_MSG, func);
        };

    
        window.mL_events.subscribeOnce = function(message, func) {
            var token = window.mL_events.subscribe(message, function() {
                // before func apply, unsubscribe message
                window.mL_events.unsubscribe(token);
                func.apply(this, arguments);
            });
            return window.mL_events;
        };
    

        window.mL_events.clearAllSubscriptions = function clearAllSubscriptions() {
            messages = {};
        };

        window.mL_events.clearSubscriptions = function clearSubscriptions(topic) {
            var m;
            for (m in messages) {
                if (messages.hasOwnProperty(m) && m.indexOf(topic) === 0) {
                    delete messages[m];
                }
            }
        };
    


        function countSubscriptions(topic) {
            var m;
            var count = 0;
            for (m in messages) {
                if (messages.hasOwnProperty(m) && m.indexOf(topic) === 0) {
                    count++;
                }
            }
            return count;
        }    
        window.mL_events.countSubscriptions = countSubscriptions;
        


        window.mL_events.getSubscriptions = function getSubscriptions(topic) {
            var m;
            var list = [];
            for (m in messages) {
                if (messages.hasOwnProperty(m) && m.indexOf(topic) === 0) {
                    list.push(m);
                }
            }
            return list;
        };


        window.mL_events.unsubscribe = function(value) {
            var descendantTopicExists = function(topic) {
                    var m;
                    for (m in messages) {
                        if (messages.hasOwnProperty(m) && m.indexOf(topic) === 0) {
                            // a descendant of the topic exists:
                            return true;
                        }
                    }

                    return false;
                },
                isTopic = typeof value === 'string' && (messages.hasOwnProperty(value) || descendantTopicExists(value)),
                isToken = !isTopic && typeof value === 'string',
                isFunction = typeof value === 'function',
                result = false,
                m, message, t;

            if (isTopic) {
                window.mL_events.clearSubscriptions(value);
                return;
            }

            for (m in messages) {
                if (messages.hasOwnProperty(m)) {
                    message = messages[m];

                    if (isToken && message[value]) {
                        delete message[value];
                        result = value;
                        // tokens are unique, so we can just stop here
                        break;
                    }

                    if (isFunction) {
                        for (t in message) {
                            if (message.hasOwnProperty(t) && message[t] === value) {
                                delete message[t];
                                result = true;
                            }
                        }
                    }
                }
            }

            return result;
        };
        */
    }

    try {
        safe_start();
    } catch (err) {
        mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
        mL_errors(err);
    }


}(window, window.mL_config, window.mL_errors));