(function(window, document, mL_config, mL, mL_errors, mL_events) {
    "use strict";

    var key = 'mlahart_globals';

    function safe_start() {
        if (!mL_config) throw new Error('The mL_config global is not available');
        if (!mL) throw new Error('The mL global is not available');

        mL.user = window.mL.user || {};

        var md5_loaded = false;
        var md5_email_value = false;

        function loadMd5(next) {
            var el = document.createElement('script');
            el.onload = function(ev) {
                md5_loaded = true;
                if (next) next();
            };
            el.src = '/static/md5.js';
            if (document && document.body) document.body.appendChild(el);
        }

        function get_hash(inp) {
            if (!CryptoJS || !CryptoJS.MD5) return false;
            return CryptoJS.MD5(inp.toLowerCase()).toString();
        }

        function set_md5_email(inp, cb) {
            var hash = get_hash(inp);
            if (hash && !md5_email_value) {
                md5_email_value = hash;
            }
            if (cb) cb();
        }

        function set_email(inp, cb) {
            if (md5_loaded) return set_md5_email(inp, cb);
            loadMd5(function() {
                return set_md5_email(inp, cb);
            });
        }

        function md5_email(inp, cb) {
            //console.log('inp', inp);
            if (inp) return set_email(inp, cb);
            return md5_email_value;
        }

        /////////////////////////////////////////////////////////////////////////////////
        /// Globals Begins        


        function readCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }

        function getParameter(href, key, val) {
            if (!href || !key) return false;
            val = href.match(new RegExp('[?&]' + key + '=([^&#]*)'));
            return (val && val[1]) ? key + '=' + val[1] + '&' : '';
        }

        function append_data(obj) {
            if (!obj.timestamp) {
                obj.timestamp = new Date().getTime().toString();
            }

            if (!obj.url) {
                obj.url = window.location.href;
            }

            if (!obj.referrer && document.referrer && document.referrer !== '') {
                obj.referrer = document.referrer;
            }

            if (!obj.shop) {
                if (mL.user && mL.user.shop_data && mL.user.shop_name) {
                    obj.shop = mL.user.shop_name;
                }
            }

            if (mL_config.params) {
                for (var n in mL_config.params) {
                    if (mL_config.params.hasOwnProperty(n)) {
                        if (!obj[n]) {
                            obj[n] = mL_config.params[n];
                        }
                    }
                }
            }

            if (mL_config.session) {
                if (!obj.mlahart_uid && mL_config.session.mlahart_uid) {
                    obj.mlahart_uid = mL_config.session.mlahart_uid;
                }
            }

            if (!obj.gaid && mL.gaid) {
                obj.gaid = mL.gaid;
            }

            if (!obj.device_type && mL.device_type) {
                obj.device_type = mL.device_type;
            }

        }

        mL.append_data = append_data;


        function processAnyForm(obj, callback) {
            try {
                append_data(obj);
            } catch (err) {
                mL_errors(err);
            }

            mL_config.post_json_req("/checkout/api/website_form.ss", JSON.stringify(obj), function(res_data) {
                var _event = obj._event || 'form.submit';

                var _data = {
                    event: _event,
                    data: obj,
                    data_res: res_data
                };

                mL_events.publish(_event, _data);

                if (callback && typeof callback === 'function') callback(res_data);
            });
        }


        function processTheFooterForm(name) {
            if (name !== 'footer') return console.log(name + ' form does not exist');
            var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
            var wrapEl = document.getElementById("ftFrm");
            var emailEl = document.getElementById("ftInp");
            var email = emailEl.value;
            var valid = email_regex.test(email);
            if (valid) {
                processAnyForm({ email: email, form: 'Footer Form', _event: 'form.subscribe' });

                wrapEl.setAttribute('class', 'success');
                emailEl.setAttribute('placeholder', 'Thank You for Signing Up!');
                emailEl.value = '';
            } else {
                wrapEl.setAttribute('class', 'error');
            }
        }


        function sync_it() {
            var el_src, new_el;
            try {
                el_src = SC.SESSION.touchpoints.serversync.replace('http://www.mlahart.com/', 'https://www.mlahart.com/');
                new_el = document.createElement('img');
                new_el.src = el_src;
                new_el.setAttribute('style', 'display:none;');
                if (document && document.body) document.body.appendChild(new_el);
            } catch (err) { mL_errors(err); }
        }

        window.tjb = window.tjb || {};
        window.tjb.processForm = processTheFooterForm;
        window.tjb.form = processAnyForm;

        mL.getParams = mL_config.get_params;

        function disable_code(str) {
            if (str === 'all') {
                mL_config.tags = {};
                mL_config.reviews.active = false;
                return;
            }

            var to_disable = str.split(',');
            var i = 0,
                l = to_disable.length;

            for (i; i < l; i += 1) {
                if (mL_config.tags[to_disable[i]]) {
                    delete mL_config.tags[to_disable[i]];
                } else if (to_disable[i] === 'reviews') {
                    mL_config.reviews.active = false;
                }
            }
        }

        if (mL.params.disable && mL_config) {
            try {
                disable_code(mL.params.disable);
            } catch (err) {
                mL_errors(err);
            }
        }

        mL.getParam = getParameter;
        mL.readCookie = readCookie;

        mL.sync_it = sync_it;
        mL.post_login_sync = false;
        mL.actions = function(obj) {
            console.log(obj);
        };

        mL.md5_email = md5_email;

        mL.user.mlahart_uid = function() {
            return readCookie('mlahart_uid');
        };

        try {
            mL.device_type = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        } catch (err) {
            mL_errors(err);
        }


        var active_version = false;

        function init_active_version() {
            var ids = ['mlahart_app', 'mlahart_fw', 'mlahart_app_css', 'mlahart_fw_css', 'mlahart_shops', 'mlahart_global', 'mlahart_global_session'];
            var i = 0;
            var l = ids.length;
            var ver_obj = {};
            var ver_arr = [];

            function get_ver(id) {
                var el = document.getElementById(id);
                var version = el && el.getAttribute('data-version');
                if (version) ver_obj[id] = version;
            }

            for (i; i < l; i += 1) {
                get_ver(ids[i]);
            }

            if (ver_obj.mlahart_fw) ver_arr.push(ver_obj.mlahart_fw);
            if (ver_obj.mlahart_app) ver_arr.push(ver_obj.mlahart_app);
            if (ver_obj.mlahart_global) ver_arr.push(ver_obj.mlahart_global);

            ver_obj.text = ver_arr.join('.') || 'unknown';

            //console.log('ver_obj', ver_obj);
            active_version = ver_obj;
            return active_version;
        }

        mL.version = function() {
            return (!active_version) ? init_active_version() : active_version;
        };

        mL.add_tracker = function(url, tagid, el, onload) {
            if (!url || !tagid || document.getElementById(tagid) || !document.head) return;
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.id = tagid;
            //el.defer = true;
            el.src = url;
            if (onload && typeof onload === 'function') {
                el.onload = onload;
            }
            document.head.appendChild(el);
        };


        mL.user = mL.user || {};

        function init_shop() {
            mL.user.shop_data = false;

            var shop_lskey = 'active_shop';
            var req_fields = ['shop_name', 'shop_urlc', 'shop_lp_code', 'shop_color', 'shop_type'];

            function is_valid(data_in) {
                var i = 0,
                    l = req_fields.length,
                    valid = true;
                for (i; i < l && valid; i += 1) {
                    if (!data_in[req_fields[i]]) {
                        valid = false;
                    }
                }
                return valid;
            }


            function get_local_storage() {
                if (!mL_config.ls) return false;

                var res_str = localStorage.getItem(shop_lskey);

                if (!res_str) return false;

                var res_obj = JSON.parse(res_str);

                if (!is_valid(res_obj)) return false;

                mL.user.shop_data = res_obj;
                return mL.user.shop_data;
            }

            function set_shop(data_in) {
                if (is_valid(data_in)) {
                    if(mL_config.ls) localStorage.setItem(shop_lskey, JSON.stringify(data_in));
                    mL.user.shop_data = data_in;
                    return true;
                }
                return false;
            }


            function get_shop() {
                return (mL.user.shop_data) ? mL.user.shop_data : get_local_storage();
            }

            function shop(data_in) {
                return (!data_in) ? get_shop() : set_shop(data_in);
            }

            get_local_storage();

            return shop;
        }

        mL.user.shop = init_shop();

        // mL_config.shop({'shop_name': 'required', 'shop_urlc': 'required', 'shop_lp_code': 'required', 'shop_color': 'required', 'shop_type': 'required'})
    }

    try {
        safe_start();
    } catch (err) {
        mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
        mL_errors(err);
    }


}(window, document, window.mL_config, window.mL, window.mL_errors, window.mL_events));


/*
    mL.jquery_audit = function() {
        var jq_obj = mL_config.jq_ver;

        if (jq_obj.error) return jq_obj.error;

        if (typeof $ === 'function') {
            jq_obj.active_ver = $.fn.jquery;
        } else if (typeof jQuery === 'function') {
            jq_obj.active_ver = jQuery.fn.jquery;
        } else {
            jq_obj.active_ver = jq_obj.active_ver;
        }

        if (!mL_config.is_dev && !mL_config.is_scraper && (mL_config.is_app || mL_config.is_checkout)) {
            if (mL_config.is_checkout) {
                if (jq_obj.active_ver !== jq_obj.checkout) {
                    jq_obj.error = 'Checkout: jQuery version ' + jq_obj.active_ver + ' is incorrect. Should be ' + jq_obj.checkout;
                }
            } else {
                if (jq_obj.active_ver !== jq_obj.site) {
                    if (mL_config.href.indexOf('Recognition-Gift-Shop') === -1) {
                        jq_obj.error = 'Website: jQuery version ' + jq_obj.active_ver + ' is incorrect. Should be ' + jq_obj.site;
                    } else if (jq_obj.active_ver !== jq_obj.site_rgs) {
                        jq_obj.error = 'Website: jQuery version ' + jq_obj.active_ver + ' is incorrect. Should be ' + jq_obj.site_rgs;
                    }
                }
            }
        }

        if (jq_obj.error) {
            mL_errors(new Error(jq_obj.error));
        }

        return jq_obj.error;
    };
*/