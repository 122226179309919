(function(window, mL, mL_errors, mL_events, mL_config, gtag) {
    "use strict";

    var key = 'GA4';

    function safe_start() {
        if (mL_config.do_not_track || !mL_config.tags || !mL_config.tags.gtag || !gtag) return;

        var debug_hooks = {
            'debug_gtag': true,
            'debug_analytics': true,
            'debug': true
        };

        var _date = new Date();
        var _debug = mL_config.mode && debug_hooks[mL_config.mode];


        var GA4_ID = 'G-1SQCXPFBK4';
        var ga_config = {
            allow_enhanced_conversions: true,
            linker: ["mlahart.com", "gotovictorylane.com", "myshopify.com", "netsuite.com", "paypal.com", "utexas-gifts.com", "shopify.com"],
            server_container_url: 'https://app.mlahart.com/mlahartco/',
            send_page_view: false
        };

        if (_debug || mL_config.is_dev || mL_config.is_scraper) {
            ga_config.debug_mode = true;
        }

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag("js", new Date());

        var privacy_default = {
            ad_storage: "granted",
            analytics_storage: "granted",
            ad_user_data: "granted",
            ad_personalization: "granted"
        };

        gtag("consent", "update", privacy_default);

        gtag("config", GA4_ID, ga_config);


        function get_ga4_item(item) {
            if (!item) return;
            var o = {
                item_id: item.sku,
                item_name: item.name || item.storedisplayname
            };

            if (item.shop_name) {
                o.item_category = item.shop_name;
            } else if (item.filters && item.filters.shop_name) {
                o.item_category = item.filters.shop_name;
            }

            if (item.collection_name) {
                o.item_category2 = item.collection_name;
            } else if (item.filters && item.filters.collection_name) {
                o.item_category2 = item.filters.collection_name;
            }

            if (item.brand) {
                o.brand = item.brand;
            } else if (item.filters && item.filters.brand) {
                o.brand = item.filters.brand;
            }

            if (item.rate) {
                o.price = parseFloat(item.rate).toFixed(2);
            } else if (item.price) {
                o.price = parseFloat(item.price).toFixed(2);
            }

            if (item.quantity) o.quantity = item.quantity;

            return o;
        }

        mL_events.subscribe('view.item', function(msg, data_in) {
            var data_out = { currency: 'USD', items: [get_ga4_item(data_in.item)] };

            gtag("event", "view_item", data_out);

            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in, data_out: data_out });
        });

        mL_events.subscribe('view.cart', function(msg, data_in) {
            if (!data_in.cart || !data_in.cart.items || data_in.cart.items.length < 1) return;

            var data_out = { currency: 'USD', items: [] };
            var i = 0;
            var l = data_in.cart.items.length;
            for (i; i < l; i += 1) {
                data_out.items.push(get_ga4_item(data_in.cart.items[i]));
            }

            gtag("event", "view_cart", data_out);

            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in, data_out: data_out });
        });

        mL_events.subscribe('page', function(msg, data_in) {

            if (mL_config.AUDIT) console.log('pubsub ' + key + ' - ' + msg, data_in);
            if (data_in.page.title && data_in.page.url) {
                gtag('event', 'page_view', { page_title: data_in.page.title, page_location: data_in.page.url });
            } else {
                gtag('event', 'page_view');
            }
            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in });
        });

        mL_events.subscribe('cart.remove_item', function(msg, data_in) {
            var data_out = { currency: 'USD', items: [get_ga4_item(data_in.item)] };

            gtag("event", "remove_from_cart", data_out);

            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in, data_out: data_out });
        });

        mL_events.subscribe('cart.add_item', function(msg, data_in) {
            var data_out = { currency: 'USD', items: [get_ga4_item(data_in.item)] };

            gtag("event", "add_to_cart", data_out);

            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in, data_out: data_out });
        });

        mL_events.subscribe('order.proceed_to_checkout', function(msg, data_in) {
            var is_paypal = data_in.is_paypal;
            var data_out = { currency: 'USD', items: [] };
            var i = 0;
            var l = data_in.items.length;
            for (i; i < l; i += 1) {
                data_out.items.push(get_ga4_item(data_in.items[i]));
            }

            gtag("event", "begin_checkout", data_out);

            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in, data_out: data_out });
        });

        mL_events.subscribe('order.pageview', function(msg, data_in) {
            gtag('event', 'page_view');
            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in });
        });

        mL_events.subscribe('order.event', function(msg, data_in) {
            var _event = data_in.event;

            if (_event.category === 'create-account') {
                gtag("event", "sign_up", { method: "NetSuite" });

            } else if (_event.category === 'sign-in') {
                gtag("event", "login", { method: "NetSuite" });

            }

            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in });
        });

        mL_events.subscribe('order.confirmation', function(msg, data_in) {
            var _order = data_in.order;
            var _order_id = (_order.order_id.indexOf('-') !== -1) ? _order.order_id.split('-')[1] : _order.order_id;

            var data_out = {
                currency: 'USD',
                transaction_id: _order_id,
                value: _order.revenue,
                shipping: _order.shipping,
                tax: _order.tax,
                items: []
            };

            if (_order.promocode && _order.promocode.isvalid) {
                data_out.coupon = _order.promocode.code;
            }

            var i = 0;
            var l = data_in.order.items.length;
            for (i; i < l; i += 1) {
                data_out.items.push(get_ga4_item(data_in.order.items[i]));
            }

            gtag("event", "purchase", data_out);

            if (_debug) console.log(key + ' - ' + msg, { msg: msg, data_in: data_in, data_out: data_out });
        });

        mL_events.subscribe('form', function(msg, data_in) {
            if (!data_in.data) return;

            gtag("event", "generate_lead");

            if (_debug) console.log(key + ' - ' + msg, data_in);
        });

        mL.gtag = mL.gtag || {};

        mL.gtag.init = function() {
            if (mL.gtag.is_init || !mL.add_tracker || !gtag) return false;

            var ga_url4 = 'https://app.mlahart.com/config.js';

            mL.add_tracker(ga_url4, 'google4');

            mL.gtag.is_init = true;

            if (mL_config.DEBUG) console.log(key + ' - plugin loaded');
        };

    }

    try {
        safe_start();
    } catch (err) {
        mL_errors(new Error('safe_start failed - ' + key.toUpperCase()));
        mL_errors(err);
    }


}(window, window.mL, window.mL_errors, window.mL_events, window.mL_config, window.gtag));

// login
// purchase
// generate_lead
// purchase
// sign_up
// select_content
// form_submit